import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

const Successpage = () => (
  <Layout>
    <Seo title="Contact Parasky Flute Studios: Success" />
    <section>
      <div className="container">
        <div
          className="has-text-centered is-flex is-flex-direction-column is-justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <h1 className="title has-text-success">Success!</h1>
          <p className="is-size-6">
            Thank you for contacting Parasky Flute Studios. We will respond to
            your message as soon as possible.
          </p>
          <Link className="button is-success" to="/" style={{ alignSelf: 'center', marginTop: '2rem' }}>Head Home <FaArrowRight className="ml-4" /></Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default Successpage
